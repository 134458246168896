/* global $ */
import '../../css/app/components/home.scss';
class Home {
    constructor() {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.slick-carousel').slick({
                autoplay: true,
                autoplaySpeed: 4000,
                fade: true,
                speed: 1000,
                pauseOnHover: false,
                arrows: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            arrows: false,
                        }
                    }
                ]
            });
        });
        console.log('Home initialised');
    }
}

new Home;